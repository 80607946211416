import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Backdrop from "@material-ui/core/Backdrop/Backdrop"
import bullhorn from "../../images/bullhorn_small.png"
import Modal from "@material-ui/core/Modal/Modal"
import ReactHtmlParser, { processNodes } from "react-html-parser"
import Link from "../Link"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  alertModal: {
    "& .alert-modal": {
      border: "none !important",
      outline: "none !important",
      backgroundColor: theme.palette.secondary.main,
      position: "absolute",
      top: 100,
      left: "50%",
      transform: "translateX(-50%)",
      padding: 40,
      width: "calc(100vw - 30px)",
      maxWidth: 500,
      [theme.breakpoints.up("md")]: {
        top: "50%",
        transform: "translate(-50%, -50%)",
        padding: 50,
      },
      "& h2": {
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.headerFont,
        textAlign: "center",
        fontWeight: "normal",
        marginTop: 16,
        marginBottom: 12,
      },
      "& .bullhorn": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 12,
        "&::before, &::after": {
          height: 1,
          backgroundColor: theme.palette.primary.main,
          content: "''",
          display: "block",
          flex: "0 0 calc(50% - 31px)",
        },
        "& img": {
          margin: "0 20px",
        },
      },
      "& p, & li": {
        color: theme.palette.secondary.contrastText,
        lineHeight: 1.5,
        textAlign: "center",
        "& a": {
          color: theme.palette.secondary.contrastText,
        },
      },
      "& .buttons": {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "16px",
        marginTop: 24,
        "& button": {
          fontFamily: theme.headerFont,
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          padding: "12px 22px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
          fontSize: 16,
          lineHeight: 1.2,
          fontWeight: 400,
          textAlign: "center",
          minWidth: 185,
          textTransform: "uppercase",
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
      "& .close": {
        color: theme.palette.secondary.contrastText,
        fontSize: 16,
        letterSpacing: "0.016em",
        lineHeight: 1.5,
        margin: 0,
        display: "block",
        verticalAlign: "middle",
        backgroundColor: "transparent",
        border: "none",
        fontFamily: theme.headerFont,
        textTransform: "uppercase",
        position: "absolute",
        padding: 0,
        top: -36,
        right: 0,
        "&:hover": {
          cursor: "pointer",
        },
        "& span": {
          backgroundColor: theme.palette.secondary.contrastText,
          width: 18,
          height: 18,
          clipPath:
            "polygon(7px 1px, 8px 0px, 10px 0px, 11px 1px, 11px 7px, 17px 7px, 18px 8px, 18px 10px, 17px 11px, 11px 11px, 11px 17px, 10px 18px, 8px 18px, 7px 17px, 7px 11px, 1px 11px, 0px 10px, 0px 8px, 1px 7px, 7px 7px)",
          display: "inline-block",
          verticalAlign: "text-top",
          transform: "rotate(45deg)",
          marginLeft: 3,
        },
      },
    },
  },
}))

export default function GlobalAlert() {
  const classes = useStyles()

  const [open, setOpen] = React.useState(true)

  const data = useStaticQuery(graphql`
    query globalAlert {
      wp {
        alertBox {
          AlertBox {
            fieldGroupName
            showAlert
            alertTitle
            alertImage {
              id
              sourceUrl
              sourceUrlSharp {
                id
                publicURL
                childImageSharp {
                  fluid(quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            alertSnippet
            alertCta1 {
              target
              title
              url
            }
            alertCta2 {
              target
              title
              url
            }
          }
        }
      }
    }
  `)

  const isBrowser = typeof window !== `undefined`

  const hasSeen = () => {
    if (isBrowser) {
      return window.sessionStorage.getItem("hasSeenGlobalAlert")
    } else {
      return true
    }
  }

  const handleClose = () => {
    setOpen(false)

    if (isBrowser) {
      window.sessionStorage.setItem("hasSeenGlobalAlert", true)
    }
  }

  const closeLink = (e, link) => {
    e.preventDefault()
    handleClose()
    navigate(link)
  }

  const modalCloseLink = node => {
    // Convert a tags to links that handle routing
    if (node.type === "tag" && node.name === "a") {
      // Replace the admin URL in the link if set
      const link = node.attribs.href
      return (
        <Link key={link} to={link} onClick={e => closeLink(e, link)}>
          {processNodes(node.children, modalCloseLink)}
        </Link>
      )
    }
  }

  //   console.log(data)

  if (hasSeen() === null && data.wp.alertBox.AlertBox.showAlert === true) {
    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.alertModal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="alert-modal">
          <button type="button" className="close" onClick={handleClose}>
            Close <span />
          </button>
          {data.wp.alertBox?.AlertBox.alertImage && (
            <Img
              fluid={
                data.wp.alertBox?.AlertBox.alertImage?.sourceUrlSharp
                  ?.childImageSharp?.fluid
              }
              alt={``}
              loading="eager"
            />
          )}
          <h2>{data.wp.alertBox?.AlertBox?.alertTitle}</h2>
          <span className="bullhorn">
            <img src={bullhorn} alt="" />
          </span>
          <p>
            {ReactHtmlParser(data.wp.alertBox?.AlertBox?.alertSnippet, {
              transform: modalCloseLink,
            })}
          </p>
          <div className="buttons">
            {data.wp.alertBox.AlertBox.alertCta1 && (
              <Link
                to={data.wp.alertBox.AlertBox.alertCta1.url}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ margin: 0 }}
                  color={"primary"}
                  onClick={handleClose}
                >
                  {ReactHtmlParser(data.wp.alertBox.AlertBox.alertCta1.title)}
                </Button>
              </Link>
            )}
            {data.wp.alertBox.AlertBox.alertCta2 && (
              <Link
                to={data.wp.alertBox.AlertBox.alertCta2.url}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ margin: 0 }}
                  color={"primary"}
                  onClick={handleClose}
                >
                  {ReactHtmlParser(data.wp.alertBox.AlertBox.alertCta2.title)}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </Modal>
    )
  } else {
    return null
  }
}
